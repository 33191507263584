import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';

import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
import useLocalStorage from '../shared/useLocalStorage';

import withStyles from '@material-ui/core/styles/withStyles';
import dashboardStyle from '../assets/jss/styles/components/dashboardStyle';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/Remove';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '../components/institution/Table';
import GridOn from '@material-ui/icons/GridOn';
import LocationOn from '@material-ui/icons/LocationOn';
import ViewList from '@material-ui/icons/ViewList';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import Slide from '@material-ui/core/Slide';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { FormControlLabel } from '@material-ui/core';
import Helmet from 'react-helmet';
import Constants from '../../static/Constants';
import ContentLoader from 'react-content-loader';
import FilterText from '../components/FilterText';
import projectStyles, { useStyles } from '../styles/projects';
import { useTranslation } from 'react-i18next';
import ProjectsSearch from '../components/ProjectsSearch.jsx';
import filterOfflineInstitutions from '../shared/filterOfflineInstitutions';
import GridView from '../components/institution/GridView';
// import MapView from '../components/institution/MapView/MapView';
import getHeaderLInks from '../shared/headerLinks.js';
import { filters } from '../shared/filtersMock';
import Switch from '@material-ui/core/Switch';
import { create } from 'apisauce';
import { Container } from 'layouts';
import { ThreeDots } from 'react-loader-spinner';
import MapWrapper from '../components/GoogleMap/MapWrapper.jsx';

const apiSauceInstance = create({ baseURL: Constants.apiUrl });
const apiSauceInstanceMapBox = create({
  baseURL: Constants.mapBoxGeoCodingUrl,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const {
  buttonSubmit,
  buttonClear,
  buttonApply,
  FilterHeading,
  MobileFilter,
  filterItem,
  clearSection,
  GridSkeletonWrapper,
} = projectStyles;

const Skeleton = props => (
  <ContentLoader
    height={250}
    width={320}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="320" height="250" />
  </ContentLoader>
);

const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((acc, param) => {
          let [keyValue, ...values] = param.split('::');
          let [key, value] = keyValue.split('=');
          if (value) {
            const paramValues = [value, ...values];
            acc[key] = (paramValues || []).map(value =>
              decodeURIComponent(value.replace(/\+/g, ' '))
            );
            return acc;
          }
          acc[key] = undefined;
          return [];
        }, {})
    : {};
};

const Institutions = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  const { pageContext } = props;

  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const helmetLink = `${Constants.appUrl}/projects`;
  const { location, navigate } = props;

  const programLink = pageContext.programUrl;

  const headerLinks = getHeaderLInks('Partners', programLink, data.noLinks);
  const [showIndustrialParks, setShowIndustrialParks] = useState(false);
  const logos = pageContext.logos || [];

  const dataSectionRef = useRef(null);

  const useless = 0;
  useEffect(() => {
    setCostRange(costRange);
    setFilterMasterData(filterMasterData);
    setProjectsCount(projectsCount);
    setFavoriteProjects(favoriteProjects);
  }, [useless]);

  const { programName, pageHeader, headerLogo, headerLogoScroll, screenName } =
    (data || {}).node || {};
  const defaultProjectsForMapView = 999999;
  const styleClasses = useStyles();
  const [industrialParks, setIndustrialParks] = useState([]);
  const [partners, setPartners] = useState([]);
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [partnersCount, setPartnersCount] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(
    getQueryStringParams(location.search || '')
  );
  const theme = useTheme();
  const [costRange, setCostRange] = useState([0, 0]);
  const [countries, setCountries] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [partnerTypes, setPartnerTypes] = useState([]);
  const [sdg, setSdg] = useState([]);
  const [source, setSource] = useState([]);
  const [institutionsView, setInstitutionsView] = useLocalStorage(
    'institutionsView',
    'map',
    null,
    true
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [filterTab, setFilterTab] = useState(0);
  const [
    loadIndustrialParksMapViewData,
    setLoadIndustrialParksMapViewData,
  ] = useState(false);
  const [
    loadPartnersMapViewDataInitial,
    setLoadPartnersMapViewDataInitial,
  ] = useState(false);
  const [loadPartnersMapViewData, setLoadPartnersMapViewData] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);
  const [isIndustrialParkProcessing, setIsIndustrialParkProcessing] = useState(
    true
  );
  const [isInstitutionProcessing, setIsInstitutionProcessing] = useState(true);
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [filterMasterData, setFilterMasterData] = useState(filters);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(
    institutionsView === 'map' ? defaultProjectsForMapView : 10
  );
  const [sortBy, setSortBy] = useState('projectTitle');
  const [sortOrder, setSortOrder] = useState('asc');
  const [projectsCount, setProjectsCount] = useState(0);
  const [isCostRangeUpdated, setCostRangeUpdated] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [clearFilterSection, setClearFilterSection] = useState(false);
  const [filterSectionExpand, setFilterSectionExpand] = useState('');
  const [favoriteProjects, setFavoriteProjects] = useLocalStorage(
    `${data.node.programCode ? data.node.programCode : ''}Favorites`,
    []
  );
  const [isFavoritesView, setisFavoritesView] = useState(false);

  //Apply Button functionality
  const [countryApplied, setCountryApplied] = useState(false);
  const [partnerTypesApplied, setPartnerTypesApplied] = useState(false);

  useEffect(() => {
    // if (!(industrialParks.length > 0)) {
    setIsIndustrialParkProcessing(true);
    apiSauceInstance
      .get(Constants.getAllIndustrialParks + `/${i18n.language}`)
      .then(industrialParks => {
        if (industrialParks && industrialParks.ok) {
          setIndustrialParks([
            ...industrialParks.data.map(ip => ({
              ...ip,
              coordinates: [ip.latitude, ip.longitude],
              countryID: ip.country.countryID,
              countryName: ip.country.countryName,
              isSEZ: true,
            })),
          ]);
        }
        setLoadIndustrialParksMapViewData(true);
        setIsIndustrialParkProcessing(false);
      })
      .catch(error => {
        if (error) {
          setIsIndustrialParkProcessing(false);
          // do something
        }
      });
    // }
  }, [i18n.language]);

  useEffect(() => {
    setIsProcessing(true);
    const filterParams = prepareFilterMasterParams(searchCriteria);
    getAllInstitutions(filterParams);
  }, [i18n.language]);

  useEffect(() => {
    setFilteredPartners([]);
    setSearchCriteria(getQueryStringParams(location.search || ''));
  }, [location.search]);

  useEffect(() => {
    const filterParams = prepareFilterMasterParams(searchCriteria);
    const filteredPartners =
      filterOfflineInstitutions(
        [
          ...partners.map(ip => ({
            ...ip,
            coordinates: [ip.latitude, ip.longitude],
            countryID: ip.country.countryID,
            countryName: ip.country.countryName,
            partnerType: ip.institutionType.institutionTypeTitle,
          })),
          ...(showIndustrialParks ? industrialParks || [] : []),
        ],
        { ...filterParams, Filters: searchCriteria },
        filterMasterData,
        favoriteProjects
      ) || [];
    setFilteredPartners([...(filteredPartners || [])]);
    setFilterValues(searchCriteria);
  }, [
    filterMasterData,
    searchCriteria,
    sortBy,
    sortOrder,
    pageSize,
    pageNumber,
    showIndustrialParks,
  ]);

  const getAllInstitutions = filterParams => {
    setIsInstitutionProcessing(true);
    apiSauceInstance
      .get(Constants.getAllInstitutions + `/${i18n.language}`)
      .then(partners => {
        if (partners && partners.ok) {
          processInstitutions(partners);
        } else {
          const ipa =
            filterOfflineInstitutions(
              [
                ...partners,
                ...(showIndustrialParks ? industrialParks || [] : []),
              ],
              { ...filterParams, Filters: searchCriteria },
              filterMasterData,
              favoriteProjects
            ) || [];
          processInstitutions({ data: ipa });
          setIsProcessing(false);
        }
        setIsInstitutionProcessing(false);
      })
      .catch(error => {
        if (error) {
          setIsInstitutionProcessing(false);
          // do something
        }
      });
  };

  const processInstitutions = partners => {
    setPartnersCount(partners.data.length);
    setPartners(partners.data);
    generateFilterMasterData(partners.data);
    setLoadPartnersMapViewData(true);
    setLoadPartnersMapViewDataInitial(true);
  };

  const generateFilterMasterData = partners => {
    var countries = [];
    var partnerTypes = [];
    partners.forEach(ip => {
      countries.push({
        countryID: ip.country.countryID,
        countryName: ip.country.countryName,
      });
      partnerTypes.push({
        partnerTypeID: ip.institutionType.institutionTypeID,
        partnerTypeName: ip.institutionType.institutionTypeTitle,
      });
    });
    const filterMasterDataCountry = [];
    const tracker = new Map();
    for (const item of countries) {
      if (!tracker.has(item.countryID)) {
        tracker.set(item.countryID, true);
        filterMasterDataCountry.push({
          countryID: item.countryID,
          countryName: item.countryName,
        });
      }
    }
    tracker.clear();
    const filterMasterDataPartnerType = [];
    for (const item of partnerTypes) {
      if (!tracker.has(item.partnerTypeID)) {
        tracker.set(item.partnerTypeID, true);
        filterMasterDataPartnerType.push({
          partnerTypeID: item.partnerTypeID,
          partnerTypeName: item.partnerTypeName,
        });
      }
    }
    filterMasterDataCountry.sort((a, b) => {
      return a.countryName.toLocaleUpperCase() >
        b.countryName.toLocaleUpperCase()
        ? 1
        : -1;
    });
    setFilterMasterData({
      COUNTRY: filterMasterDataCountry,
      PARTNERTYPE: filterMasterDataPartnerType,
    });
    setIsProcessing(false);
  };

  useEffect(() => {
    if (loadPartnersMapViewDataInitial && partners && partners.length > 0) {
      Promise.all(
        partners.map(async partner => {
          if (
            partner.country.countryName &&
            !partner.longitude &&
            !partner.latitude
          ) {
            const searchText = `${partner.country.countryName} ${
              partner.region ? partner.region.regionName : ''
            } ${partner.city ? partner.city.cityName : ''}`;
            const url = `/${searchText}.json?access_token=${Constants.mapBoxApiKey}`;
            const mapCoordinates = await apiSauceInstanceMapBox.get(url);
            if (
              mapCoordinates.data &&
              mapCoordinates.data.features.length > 0
            ) {
              if (
                mapCoordinates.data.features[0].geometry &&
                mapCoordinates.data.features[0].geometry.coordinates
              ) {
                return {
                  ...partner,
                  longitude:
                    mapCoordinates.data.features[0].geometry.coordinates[0] ||
                    0,
                  latitude:
                    mapCoordinates.data.features[0].geometry.coordinates[1] ||
                    0,
                  coordinates: (
                    mapCoordinates.data.features[0].geometry.coordinates || []
                  ).reverse(),
                };
              }
            }
            return { ...partner, longitude: 0, latitude: 0 };
          } else {
            return partner;
          }
        })
      )
        .then(partner => {
          setLoadPartnersMapViewDataInitial(false);
          setPartners(partner);
        })
        .catch(() => {
          setLoadPartnersMapViewDataInitial(false);
        });
    }
  }, [partners, loadPartnersMapViewDataInitial]);

  useEffect(() => {
    if (
      loadPartnersMapViewData &&
      filteredPartners &&
      filteredPartners.length > 0
    ) {
      Promise.all(
        filteredPartners.map(async partner => {
          if (
            partner.countryName &&
            !(partner.coordinates || [])[0] &&
            !(partner.coordinates || [])[1]
          ) {
            const searchText = `${partner.countryName} ${
              partner.region ? partner.region.regionName : ''
            } ${partner.city ? partner.city.cityName : ''}`;
            const url = `/${searchText}.json?access_token=${Constants.mapBoxApiKey}`;
            const mapCoordinates = await apiSauceInstanceMapBox.get(url);
            if (
              mapCoordinates.data &&
              mapCoordinates.data.features.length > 0
            ) {
              if (
                mapCoordinates.data.features[0].geometry &&
                mapCoordinates.data.features[0].geometry.coordinates
              ) {
                return {
                  ...partner,
                  longitude:
                    mapCoordinates.data.features[0].geometry.coordinates[0] ||
                    0,
                  latitude:
                    mapCoordinates.data.features[0].geometry.coordinates[1] ||
                    0,
                  coordinates: (
                    mapCoordinates.data.features[0].geometry.coordinates || []
                  ).reverse(),
                };
              }
            }
            return { ...partner, longitude: 0, latitude: 0 };
          } else {
            return partner;
          }
        })
      )
        .then(partner => {
          setLoadPartnersMapViewData(false);
          setFilteredPartners(partner);
        })
        .catch(() => {
          setLoadPartnersMapViewData(false);
        });
    }
  }, [filteredPartners, loadPartnersMapViewData]);

  useEffect(() => {
    if (
      loadIndustrialParksMapViewData &&
      industrialParks &&
      industrialParks.length > 0
    ) {
      Promise.all(
        industrialParks.map(async industrialPark => {
          if (
            industrialPark.countryName &&
            !(industrialPark.coordinates || [])[0] &&
            !(industrialPark.coordinates || [])[1]
          ) {
            const searchText = `${industrialPark.countryName} ${
              industrialPark.region ? industrialPark.region.regionName : ''
            } ${industrialPark.city ? industrialPark.city.cityName : ''}`;
            const url = `/${searchText}.json?access_token=${Constants.mapBoxApiKey}`;
            const mapCoordinates = await apiSauceInstanceMapBox.get(url);
            if (
              mapCoordinates.data &&
              mapCoordinates.data.features.length > 0
            ) {
              if (
                mapCoordinates.data.features[0].geometry &&
                mapCoordinates.data.features[0].geometry.coordinates
              ) {
                return {
                  ...industrialPark,
                  longitude:
                    mapCoordinates.data.features[0].geometry.coordinates[0] ||
                    0,
                  latitude:
                    mapCoordinates.data.features[0].geometry.coordinates[1] ||
                    0,
                  coordinates: (
                    mapCoordinates.data.features[0].geometry.coordinates || []
                  ).reverse(),
                };
              }
            }
            return { ...industrialPark, longitude: 0, latitude: 0 };
          } else {
            return industrialPark;
          }
        })
      )
        .then(industrialPark => {
          setLoadIndustrialParksMapViewData(false);
          setIndustrialParks(industrialPark);
        })
        .catch(() => {
          setLoadIndustrialParksMapViewData(false);
        });
    }
  }, [industrialParks, loadIndustrialParksMapViewData]);

  useEffect(() => {
    if (clearFilterSection) {
      setClearFilterSection(false);
      filterClick('filter')();
    }
  }, [clearFilterSection]);

  const filterClick = (action, inputQuery = null) => () => {
    let params = {};
    let queryString = '';
    if (action === 'filter' || action === 'clearSearch') {
      params = {
        search: searchString && action === 'filter' ? searchString : '',
        Country: countries ? countries : [],
        partnerType: partnerTypes ? partnerTypes : [],
      };
      Object.keys(params).forEach(key => {
        if (!params[key] || params[key].length === 0) {
          delete params[key];
        }
      });

      queryString =
        '?' +
        Object.keys(params)
          .map(key =>
            key === 'search'
              ? `${key}=${params[key]}`
              : `${key}=${params[key].reduce((acc, value) => {
                  return acc === '' ? `${acc}${value}` : `${acc}::${value}`;
                }, '')}`
          )
          .join('&');
      setOpenDialog(false);
    } else {
      setCostRange([
        0,
        ((filterMasterData || {}).TOTALINVESTMENTCOST || {}).max || 999999,
      ]);
      setCountries([]);
      setSectors([]);
      setPartnerTypes([]);
      setSdg([]);
      setCostRangeUpdated(false);
      setSource([]);
      setSearchString('');
      setisFavoritesView(false);
      setPageNumber(0);
    }
    setPageNumber(0);
    setPageSize(institutionsView === 'map' ? defaultProjectsForMapView : 10);
    navigate(`${location.pathname}${inputQuery || queryString}`, {}).then(
      () => {
        if (dataSectionRef.current) {
          dataSectionRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    );
    setAllFilters();
  };

  const prepareFilterMasterParams = params => {
    let filters = [];
    let filterMasterParams = {};
    if (params) {
      if (Object.keys(params).find(key => key === 'Favorites')) {
        const val = {
          Name: 'Favorites',
          Value: JSON.parse(
            localStorage.getItem(
              `${data.node.programCode ? data.node.programCode : ''}Favorites`
            )
          ),
        };
        filters.push(val);
      } else {
        Object.keys(params).map(function(key) {
          let val = {};
          if (key === 'search') {
            val = {
              Name: key,
              Value: (params[key] || [''])[0],
            };
          } else {
            if (params[key].length > 1) {
              val = {
                Name: key === 'InvestmentType' ? 'PROJECTOPPORTUNITYTYPE' : key,
                Value: params[key].map(Number),
              };
            } else if (params[key].length > 0) {
              val = {
                Name: key === 'InvestmentType' ? 'PROJECTOPPORTUNITYTYPE' : key,
                Value: [parseInt(params[key])],
              };
            }
          }
          filters.push(val);
        });
      }

      filterMasterParams = {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortBy: sortBy,
        SortOrder: sortOrder,
        Filters: filters,
      };
    }
    return filterMasterParams;
  };

  const setFilterValues = params => {
    const filterMap = {
      Country: setCountries,
      TotalInvestmentCost: setCostRange,
      Sector: setSectors,
      updated: () => {},
      InvestmentType: setPartnerTypes,
      sdg: setSdg,
      search: setSearchString,
      Source: setSource,
    };

    if (Object.keys(params).find(key => key === 'Favorites')) {
      setisFavoritesView(true);
      return;
    } else {
      setisFavoritesView(false);
    }

    Object.keys(params).map(key => {
      const setFilter = filterMap[key] || (() => {});
      key === 'search'
        ? setFilter((params[key] || [''])[0])
        : setFilter(params[key].filter(e => e != '').map(val => parseInt(val)));
    });
  };

  const updateStateElement = (
    stateCollection,
    updateState,
    updateChange
  ) => value => () => {
    const collection = stateCollection || [];

    const currentIndex = (collection || []).indexOf(value);
    const updatedState =
      currentIndex === -1
        ? [...collection, value]
        : collection
            .slice(0, currentIndex)
            .concat(collection.slice(currentIndex + 1));

    updateState(updatedState);
    updateChange(true);
  };

  const handleCountriesChange = updateStateElement(
    countries,
    setCountries,
    setCountryApplied
  );

  const handlePartnerTypesChange = updateStateElement(
    partnerTypes,
    setPartnerTypes,
    setPartnerTypesApplied
  );

  const changeProjectView = view => () => {
    setPageNumber(0);
    setPageSize(view === 'map' ? defaultProjectsForMapView : 10);
    setInstitutionsView(view);
  };

  const updateFilter = setFilter => value => {
    setFilteredPartners([]);
    setFilter && setFilter(value);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleFilterTabChange = (event, newValue) => {
    setFilterTab(newValue);
  };

  // Search Functionality
  const onInstitutionsSearch = action => {
    action
      ? searchString.length === 0
        ? location.href &&
          location.href.indexOf('?') > 0 &&
          filterClick('clearSearch')()
        : searchString.length > 2 && filterClick('filter')()
      : location.href &&
        location.href.indexOf('?') > 0 &&
        filterClick('clearSearch')();
  };

  // Filter Expansion panel

  const handleExpansionClick = panel => (event, isExpanded) => {
    setFilterSectionExpand(isExpanded ? panel : false);
  };

  const setAllFilters = () => {
    setCountryApplied(false);
    setPartnerTypesApplied(false);
  };

  return (
    <Layout
      programLink={programLink}
      title={t('ipi')}
      content={[t('ipi_sub_1')]}
      imageName="bg-partners.png"
      contentAlignmentStyle={'textCenter'}
      headerLinks={headerLinks}
      logos={logos}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      visitedLinks={[{ url: '/', key: 'home', name: 'Home' }]}
      currentPage={{ key: 'ipi' }}
      screenName={screenName}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Institutions')}</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>
      <div className="container">
        <GridContainer
          style={{ marginBottom: '40px' }}
          className="projects-container"
        >
          <GridItem md={4}>
            <ProjectsSearch
              onProjectsSearch={onInstitutionsSearch}
              searchString={searchString}
              setSearchString={setSearchString}
              placeholder={t('search_institutions')}
            />
            {!mobileScreen ? (
              <>
                <div className="filters-container">
                  <ExpansionPanel
                    expanded={filterSectionExpand === 'country'}
                    onChange={handleExpansionClick('country')}
                    className={styleClasses.panel}
                  >
                    <ExpansionPanelSummary
                      className={styleClasses.summary}
                      expandIcon={
                        filterSectionExpand === 'country' ? (
                          <ExpandLessIcon className={styleClasses.expand} />
                        ) : (
                          <ExpandMoreIcon className={styleClasses.expand} />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        className={`${styleClasses.heading} ${
                          filterSectionExpand === 'country'
                            ? styleClasses.headingExpanded
                            : ''
                        } `}
                      >
                        {t('Country')}
                        {countries.length ? (
                          <ClearFilterSection
                            handleClick={() => {
                              setCountries([]);
                              setClearFilterSection(true);
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {countryApplied ? (
                          <ApplyFilterSection
                            handleClick={filterClick('filter')}
                          />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails className={styleClasses.contents}>
                      <List>
                        {filterMasterData &&
                          (filterMasterData.COUNTRY || []).map(value => {
                            return (
                              <ListItem key={value.countryID}>
                                <Checkbox
                                  color="primary"
                                  className={styleClasses.checkbox}
                                  edge="start"
                                  checked={
                                    countries.indexOf(value.countryID) !== -1
                                  }
                                  onChange={handleCountriesChange(
                                    value.countryID
                                  )}
                                  tabIndex={-1}
                                  disableRipple
                                  value={value.countryID}
                                ></Checkbox>
                                <p css={filterItem}>{value.countryName}</p>
                              </ListItem>
                            );
                          })}
                      </List>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel
                    expanded={filterSectionExpand === 'partnerType'}
                    onChange={handleExpansionClick('partnerType')}
                    className={styleClasses.panel}
                  >
                    <ExpansionPanelSummary
                      className={styleClasses.summary}
                      expandIcon={
                        filterSectionExpand === 'partnerType' ? (
                          <ExpandLessIcon className={styleClasses.expand} />
                        ) : (
                          <ExpandMoreIcon className={styleClasses.expand} />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        className={`${styleClasses.heading} ${
                          filterSectionExpand === 'partnerType'
                            ? styleClasses.headingExpanded
                            : ''
                        } `}
                      >
                        {t('type')}
                        {partnerTypes.length ? (
                          <ClearFilterSection
                            handleClick={() => {
                              setPartnerTypes([]);
                              setClearFilterSection(true);
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {partnerTypesApplied ? (
                          <ApplyFilterSection
                            handleClick={filterClick('filter')}
                          />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails className={styleClasses.contents}>
                      <List>
                        {filterMasterData &&
                          (filterMasterData.PARTNERTYPE || []).map(value => {
                            return (
                              <ListItem key={value.partnerTypeID}>
                                <Checkbox
                                  color="primary"
                                  className={styleClasses.checkbox}
                                  edge="start"
                                  checked={
                                    partnerTypes.indexOf(
                                      value.partnerTypeID
                                    ) !== -1
                                  }
                                  onChange={handlePartnerTypesChange(
                                    value.partnerTypeID
                                  )}
                                  tabIndex={-1}
                                  disableRipple
                                  value={value.partnerTypeID}
                                ></Checkbox>
                                <p css={filterItem}>{value.partnerTypeName}</p>
                              </ListItem>
                            );
                          })}
                      </List>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
                <div className="filter-actions">
                  <button
                    onClick={filterClick('filter')}
                    className="submit-button"
                    disabled={
                      !countries.length &&
                      !sectors.length &&
                      !sdg.length &&
                      !partnerTypes.length &&
                      searchString.length < 3 &&
                      !isCostRangeUpdated &&
                      !source.length
                    }
                  >
                    {t('Submit')}
                  </button>
                  {
                    {
                      false: null,
                      true: (
                        <button
                          onClick={filterClick('clear')}
                          className="submit-button submit-clear"
                        >
                          {t('Clear')}
                        </button>
                      ),
                    }[location.href && location.href.indexOf('?') > 3]
                  }
                </div>
              </>
            ) : (
              <>
                <Button color="inherit" onClick={() => setOpenDialog(true)}>
                  <FilterList />
                  <FilterHeading>{t('Filter')}</FilterHeading>
                </Button>
                <Dialog
                  fullScreen
                  open={openDialog}
                  onClose={handleDialogClose}
                  TransitionComponent={Transition}
                  css={MobileFilter}
                >
                  <AppBar className={styleClasses.appBar}>
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                      <Typography variant="h6" className={styleClasses.title}>
                        {t('Filter')}
                      </Typography>
                      <Button
                        color="inherit"
                        onClick={filterClick('filter')}
                        disabled={
                          !countries.length &&
                          !sectors.length &&
                          !sdg.length &&
                          !partnerTypes.length &&
                          searchString.length < 3 &&
                          !isCostRangeUpdated
                        }
                      >
                        {t('Submit')}
                      </Button>
                    </Toolbar>
                  </AppBar>
                  <div className={styleClasses.root}>
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={filterTab}
                      onChange={handleFilterTabChange}
                      aria-label="Vertical tabs example"
                      className={styleClasses.tabs}
                    >
                      <Tab
                        className={styleClasses.tabItem}
                        label={t('Investment Promotion Institutions Type')}
                        {...filterTabs(0)}
                      />
                      <Tab
                        className={styleClasses.tabItem}
                        label={t('Country')}
                        {...filterTabs(1)}
                      />
                    </Tabs>

                    <TabPanel
                      value={filterTab}
                      index={0}
                      className={styleClasses.tabsPanel}
                    >
                      <div className={styleClasses.contentsMobile}>
                        <List>
                          {filterMasterData &&
                            (filterMasterData.PARTNERTYPE || []).map(value => {
                              return (
                                <ListItem key={value.partnerTypeID}>
                                  <Checkbox
                                    color="primary"
                                    className={styleClasses.checkbox}
                                    edge="start"
                                    checked={
                                      partnerTypes.indexOf(
                                        value.partnerTypeID
                                      ) !== -1
                                    }
                                    onChange={handlePartnerTypesChange(
                                      value.partnerTypeID
                                    )}
                                    tabIndex={-1}
                                    disableRipple
                                    value={value.partnerTypeID}
                                  ></Checkbox>
                                  <p css={filterItem}>
                                    {value.partnerTypeName}
                                  </p>
                                </ListItem>
                              );
                            })}
                        </List>
                      </div>
                    </TabPanel>
                    <TabPanel
                      value={filterTab}
                      index={1}
                      className={styleClasses.tabsPanel}
                    >
                      <div className={styleClasses.contentsMobile}>
                        <List>
                          {filterMasterData &&
                            filterMasterData.COUNTRY &&
                            filterMasterData.COUNTRY.map(value => {
                              return (
                                <ListItem key={value.countryID}>
                                  <Checkbox
                                    color="primary"
                                    className={styleClasses.checkbox}
                                    edge="start"
                                    checked={
                                      countries.indexOf(value.countryID) !== -1
                                    }
                                    onChange={handleCountriesChange(
                                      value.countryID
                                    )}
                                    tabIndex={-1}
                                    disableRipple
                                    value={value.countryID}
                                  ></Checkbox>
                                  <p>{value.countryName}</p>
                                </ListItem>
                              );
                            })}
                        </List>
                      </div>
                    </TabPanel>
                  </div>
                </Dialog>
                {
                  {
                    false: null,
                    true: (
                      <button
                        onClick={filterClick('clear')}
                        css={[buttonSubmit, buttonClear]}
                        color="inherit"
                      >
                        {t('Clear')}
                      </button>
                    ),
                  }[location.href && location.href.indexOf('?') > 0]
                }
              </>
            )}
          </GridItem>

          <GridItem md={8}>
            <GridContainer className="project-top">
              <GridItem md={7} xs={12} className="filter-text">
                {
                  {
                    true: null,
                    false:
                      partnersCount > 0 ||
                      Object.keys(searchCriteria).length > 0 ? (
                        <FilterText
                          filterMasterData={filterMasterData}
                          searchCriteria={searchCriteria}
                          totalProjectCount={partnersCount}
                          currentProjectCount={
                            (
                              filteredPartners.filter(
                                partner => !partner.isSEZ
                              ) || {}
                            ).length || 0
                          }
                          location={location}
                          navigate={navigate}
                          filterClick={filterClick('clear')}
                          placeholderSingle={t('partner')}
                          placeholder={t('partners')}
                        />
                      ) : (
                        ''
                      ),
                  }[isProcessing]
                }
              </GridItem>

              <GridItem md={5} xs={12} className="project-view">
                <div ref={dataSectionRef}>
                  <Tooltip title={t('grid_view')} placement="top">
                    <IconButton
                      edge="start"
                      className={
                        institutionsView == 'grid' ? 'active-color' : ''
                      }
                      onClick={changeProjectView('grid')}
                      aria-label="grid"
                    >
                      <GridOn />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={t('list_view')} placement="top">
                    <IconButton
                      edge="start"
                      className={
                        institutionsView == 'list' ? 'active-color' : ''
                      }
                      onClick={changeProjectView('list')}
                      aria-label="list"
                    >
                      <ViewList />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={t('map_view')} placement="top">
                    <IconButton
                      edge="start"
                      className={
                        institutionsView == 'map' ? 'active-color' : ''
                      }
                      onClick={changeProjectView('map')}
                      aria-label="map"
                    >
                      <LocationOn />
                    </IconButton>
                  </Tooltip>
                </div>
              </GridItem>
            </GridContainer>
            {
              {
                grid: (
                  <div>
                    {filteredPartners ? (
                      <GridView
                        gridData={filteredPartners}
                        totalProjects={
                          (
                            filteredPartners.filter(
                              partner => !partner.isSEZ
                            ) || {}
                          ).length
                        }
                        pageNumber={pageNumber}
                        pageSize={pageSize}
                        onPageNumberChange={updateFilter(setPageNumber)}
                        onPageSizeChange={updateFilter(setPageSize)}
                        fallback={<GridSkeleton />}
                        isLoading={isProcessing}
                        programLink={programLink}
                        programCode={programName}
                        handleFavoriteProjects={() => {}}
                        favoriteProjects={favoriteProjects}
                      />
                    ) : (
                      <Container width="100%">
                        <div className="three-dots-loader">
                          <ThreeDots
                            color="#5990ff"
                            height={100}
                            width={100}
                            timeout={10000}
                          />
                        </div>
                      </Container>
                    )}
                  </div>
                ),
                list: (
                  <div>
                    {filteredPartners ? (
                      <Table
                        tableHeaderColor="warning"
                        tableHead={[t('Name'), t('Description'), t('Location')]}
                        tableData={filteredPartners}
                        totalProjects={
                          (
                            filteredPartners.filter(
                              partner => !partner.isSEZ
                            ) || {}
                          ).length
                        }
                        pageNumber={pageNumber}
                        pageSize={pageSize}
                        onPageNumberChange={updateFilter(setPageNumber)}
                        onPageSizeChange={updateFilter(setPageSize)}
                        onSortByChange={updateFilter(setSortBy)}
                        onSortOrderChange={updateFilter(setSortOrder)}
                        isFavoritesView={isFavoritesView}
                        fallback={
                          <div style={{ lineHeight: 3 }}>
                            {[1, 2, 3, 4, 5, 6].map(num => (
                              <Skeleton count={10} key={num} height={10} />
                            ))}
                          </div>
                        }
                        isLoading={isProcessing}
                        programLink={programLink}
                        programCode={
                          data.node.programCode ? data.node.programCode : ''
                        }
                        handleFavoriteProjects={() => {}}
                        favoriteProjects={favoriteProjects}
                      />
                    ) : (
                      <Container width="100%">
                        <div className="three-dots-loader">
                          <ThreeDots
                            color="#5990ff"
                            height={100}
                            width={100}
                            timeout={10000}
                          />
                        </div>
                      </Container>
                    )}
                  </div>
                ),
                map: (
                  <>
                    <span>{t('show_industrial_parks')}: </span>
                    <Switch
                      checked={showIndustrialParks}
                      onChange={e => setShowIndustrialParks(e.target.checked)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    {/* leaflet map view */}
                    {/* <MapView
                      style={{ width: '100%' }}
                      partners={filteredPartners}
                      zoomControl={true}
                      markerText={'Hello, this is a marker'}
                      fallback={
                        <GridItem xs={12} sm={12} md={12}>
                          <Skeleton height={!mobileScreen ? 500 : 100} />
                        </GridItem>
                      }
                      isLoading={isProcessing}
                      programLink={programLink}
                      programCode={
                        data.node.programCode ? data.node.programCode : ''
                      }
                      handleFavoriteProjects={() => {}}
                      favoriteProjects={[]}
                    /> */}
                    {/* Google map view */}
                    <MapWrapper
                      style={{ width: '100%' }}
                      partners={filteredPartners}
                      zoomControl={true}
                      markerText={'Hello, this is a marker'}
                      fallback={
                        <GridItem xs={12} sm={12} md={12}>
                          <Skeleton height={!mobileScreen ? 500 : 100} />
                        </GridItem>
                      }
                      isLoading={
                        isProcessing ||
                        isInstitutionProcessing ||
                        isIndustrialParkProcessing
                      }
                      programLink={programLink}
                      programCode={
                        data.node.programCode ? data.node.programCode : ''
                      }
                      handleFavoriteProjects={() => {}}
                      favoriteProjects={[]}
                      clusterPage={true}
                      pageTypes={{
                        industrials: false,
                        partners: true,
                        projects: false,
                        countries: false,
                      }}
                    />
                  </>
                ),
              }[institutionsView]
            }
          </GridItem>
        </GridContainer>
      </div>
    </Layout>
  );
};

export default withStyles(dashboardStyle)(Institutions);

const GridSkeleton = () => {
  const gridArray = Array.from(Array(2).keys());
  return (
    <GridSkeletonWrapper>
      {gridArray.map(e => {
        return (
          <GridItem xs={12} sm={12} md={6} key={e}>
            <Skeleton height={200} />
          </GridItem>
        );
      })}
    </GridSkeletonWrapper>
  );
};

const ClearFilterSection = ({ handleClick }) => {
  return (
    <FormControlLabel
      aria-label="Acknowledge"
      css={clearSection}
      onClick={event => event.stopPropagation()}
      onFocus={event => event.stopPropagation()}
      control={
        <IconButton
          size="small"
          color="inherit"
          onClick={handleClick}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      }
    />
  );
};

const ApplyFilterSection = ({ handleClick, updateElement }) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      aria-label="Acknowledge"
      css={clearSection}
      onClick={() => (updateElement ? updateElement(false) : '')}
      onFocus={event => event.stopPropagation()}
      control={
        <button css={buttonApply} onClick={handleClick}>
          {t('apply')}
        </button>
      }
    />
  );
};

ClearFilterSection.propTypes = {
  handleClick: PropTypes.func,
};

ApplyFilterSection.propTypes = {
  handleClick: PropTypes.func,
  updateElement: PropTypes.func,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

Institutions.propTypes = {
  classes: PropTypes.any,
  location: PropTypes.object,
  navigate: PropTypes.func,
  pageContext: PropTypes.any,
};

function filterTabs(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
