import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
import Image from '../Image';
import LocationOn from '@material-ui/icons/LocationOn';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../Table/TablePaginationActions';
import NoProjects from '../NoProjects';
import styles from './GridView.styles';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import IconButton from '@material-ui/core/IconButton';

const { TableGridPagination } = styles;

const GridView = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    gridData,
    totalProjects,
    isLoading,
    programLink,
    fallback,
    pageNumber,
    pageSize,
    onPageNumberChange,
    onPageSizeChange,
  } = props;

  const handlePageNumberChange = (event, page) => {
    onPageNumberChange(page);
  };

  const handleChangeRowsPerPage = event => {
    onPageSizeChange(parseInt(event.target.value, 10));
  };

  const getAppendedCommaValue = value => {
    let output = '';
    if (value) {
      output = value + ', ';
    }
    return output;
  };

  return isLoading ? (
    <div>{fallback}</div>
  ) : (
    <GridContainer>
      {gridData && gridData.length > 0
        ? [
            ...(gridData || [])
              .slice(pageNumber * pageSize, pageNumber * pageSize + pageSize)
              .map(
                (partner, key) =>
                  !partner.isSEZ && (
                    <GridItem xs={12} sm={6} md={6} xl={6} key={key}>
                      <Link
                        to={`${programLink}/partner/?id=${partner.institutionID}`}
                      >
                        <Card className="project-card partner-card">
                          <CardActionArea>
                            <div className="partner-image">
                              {partner.institutionLogo &&
                              partner.institutionLogo.length > 0 ? (
                                <img
                                  alt="default Industrial Park"
                                  src={partner.institutionLogo}
                                />
                              ) : (
                                <Image imageName="Manufacturing.png" />
                              )}
                            </div>
                            <div className="project-content">
                              <CardContent className="card-details">
                                <div className="type-fav-container">
                                  <div
                                    className={`type-container id-${partner.institutionType.institutionTypeID}`}
                                  >
                                    {partner.institutionType
                                      .institutionTypeTitle || 'NATIONAL'}
                                  </div>
                                </div>
                                <div className="description-container">
                                  <div className="project-title">
                                    <Tooltip
                                      title={partner.institutionName}
                                      placement="top-start"
                                    >
                                      <h4>{partner.institutionName}</h4>
                                    </Tooltip>
                                  </div>
                                  <div className="project-description">
                                    <Tooltip
                                      title={partner.institutionDescription}
                                      placement="top-start"
                                    >
                                      <p>{partner.institutionDescription}</p>
                                    </Tooltip>
                                  </div>
                                  <div className="project-location">
                                    <Tooltip
                                      title={
                                        getAppendedCommaValue(
                                          partner.region
                                            ? partner.region.regionName
                                            : ''
                                        ) +
                                        getAppendedCommaValue(
                                          partner.city
                                            ? partner.city.cityName
                                            : ''
                                        ) +
                                        partner.country.countryName
                                      }
                                      placement="top-start"
                                    >
                                      <p>
                                        <LocationOn />
                                        {getAppendedCommaValue(
                                          partner.city
                                            ? partner.city.cityName
                                            : ''
                                        ) +
                                          getAppendedCommaValue(
                                            partner.region
                                              ? partner.region.regionName
                                              : ''
                                          ) +
                                          partner.country.countryName}
                                      </p>
                                    </Tooltip>
                                  </div>
                                </div>
                              </CardContent>
                              <CardActions className="project-action">
                                <IconButton>
                                  <ArrowRightAltIcon />
                                </IconButton>
                              </CardActions>
                            </div>
                          </CardActionArea>
                        </Card>
                      </Link>
                    </GridItem>
                  )
              ),
            <GridContainer key="pager">
              <GridItem xs={12} sm={12} md={12}>
                <Table>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        css={TableGridPagination}
                        rowsPerPageOptions={[10, 20, 30]}
                        colSpan={3}
                        count={totalProjects}
                        rowsPerPage={pageSize}
                        page={pageNumber}
                        SelectProps={{
                          inputProps: { 'aria-label': t('partners_per_page') },
                          native: true,
                        }}
                        labelRowsPerPage={t('partners_per_page')}
                        onChangePage={handlePageNumberChange}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </GridItem>
            </GridContainer>,
          ]
        : !isLoading && <NoProjects data="Partners" />}
    </GridContainer>
  );
};

GridView.propTypes = {
  gridData: PropTypes.any,
  totalProjects: PropTypes.number,
  onPageNumberChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  isLoading: PropTypes.bool,
  programLink: PropTypes.string,
  programCode: PropTypes.string,
  handleFavoriteProjects: PropTypes.func,
  favoriteProjects: PropTypes.array,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  fallback: PropTypes.element,
};

export default GridView;
