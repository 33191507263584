

export const filters = {

  COUNTRY: [

    { countryID: 55, countryName: 'Ghana', },
    { countryID: 75, countryName: 'Papua New Guinea', },
    { countryID: 82, countryName: 'Zambia', },
    { countryID: 91, countryName: 'Ethiopia', },
    { countryID: 120, countryName: 'Tanzania', },
    { countryID: 149, countryName: 'Colombia', },
    { countryID: 151, countryName: 'Cameroon', },
    { countryID: 169, countryName: 'Kenya', },
    { countryID: 182, countryName: 'Senegal', },
    { countryID: 191, countryName: 'Dominican Republic', },
    { countryID: 85, countryName: 'Egypt', },
    { countryID: 141, countryName: 'NEW ZEALAND', },
    { countryID: 251, countryName: 'Cote d\'Ivoire', },
    { countryID: 236, countryName: 'Angola' },
    { countryID: 228, countryName: 'Antigua and Barbuda' },
    { countryID: 25, countryName: 'Belize' },
  ],
  PARTNERTYPE: [
    { partnerTypeID: 1, partnerTypeName: 'REGIONAL' },
    { partnerTypeID: 2, partnerTypeName: 'NATIONAL' },
    { partnerTypeID: 3, partnerTypeName: 'SUB-NATIONAL' },
  ],
};

export const sezFilters = {

  COUNTRY: [
    { countryID: 3, countryName: 'Angola', },
    { countryID: 151, countryName: 'Cameroon', },
    { countryID: 91, countryName: 'Ethiopia', },
    { countryID: 92, countryName: 'Fiji Islands', },
    { countryID: 55, countryName: 'Ghana', },
    { countryID: 169, countryName: 'Kenya', },
    { countryID: 185, countryName: 'Mozambique', },
    { countryID: 182, countryName: 'Senegal', },
    { countryID: 186, countryName: 'Trinidad and Tobago', },
    { countryID: 228, countryName: 'Antigua and Barbuda', },
  ],
  SECTOR: [
    { companySectorCodeID: 1, isicCode: 'A', description: 'Agriculture, hunting and forestry' },
    { companySectorCodeID: 79, isicCode: 'D17', description: 'Manufacture of textiles' },
    { companySectorCodeID: 180, isicCode: 'D29', description: 'Manufacture of machinery and equipment n.e.c.' },
    { companySectorCodeID: 202, isicCode: 'D31', description: 'Construction' },
    { companySectorCodeID: 340, isicCode: 'H55', description: 'Hotels and restaurants' },
  ],
  PARTNERTYPE: [
    { partnerTypeID: 1, partnerTypeName: 'REGIONAL' },
    { partnerTypeID: 2, partnerTypeName: 'NATIONAL' },
    { partnerTypeID: 3, partnerTypeName: 'SUB-NATIONAL' },
  ],
};

export const PartnerTypes = [
  { partnerTypeID: 1, partnerTypeName: 'REGIONAL' },
  { partnerTypeID: 2, partnerTypeName: 'NATIONAL' },
  { partnerTypeID: 3, partnerTypeName: 'SUB-NATIONAL' },
];