import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// core components
import tableStyle from '../../assets/jss/styles/components/tableStyle';
import TablePaginationActions from './TablePaginationActions';
import NoProjects from '../NoProjects';
import { useTranslation } from 'react-i18next';
import SourceChip from './SourceChip';

function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] == 'string') {
    if (b[orderBy].toUpperCase() < a[orderBy].toUpperCase()) {
      return -1;
    }
    if (b[orderBy].toUpperCase() > a[orderBy].toUpperCase()) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function CustomTable({ ...props }) {
  const { t } = useTranslation();
  const {
    classes,
    tableHead,
    tableData,
    tableHeaderColor,
    totalProjects,
    pageNumber,
    pageSize,
    onPageSizeChange,
    onPageNumberChange,
    isLoading,
    fallback,
    programLink,
  } = props;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const handleChangeRowsPerPage = event => {
    onPageSizeChange(parseInt(event.target.value, 10));
  };

  const handleSortByChange = property => () => {
    const isAsc = orderBy === getNameByTitle(property) && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(getNameByTitle(property));
  };

  const handlePageNumberChange = (event, page) => {
    onPageNumberChange(page);
  };

  const getNameByTitle = title => {
    if (title) {
      if (title === 'Name') {
        return 'institutionName';
      } else if (title === 'Description') {
        return 'institutionDescription';
      } else if (title === 'Location') {
        return 'countryName';
      }
    }
  };

  const getAppendedCommaValue = value => {
    let output = '';
    if (value) {
      output = value + ', ';
    }
    return output;
  };

  return isLoading ? (
    <div>{fallback}</div>
  ) : (
    <div className={classes.tableResponsive}>
      {tableData && tableData.length > 0 ? (
        <Table className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
              <TableRow className={classes.tableHeadRow}>
                {(tableHead || []).map((name, key) => {
                  return (
                    <TableCell
                      className={
                        classes.tableCell + ' ' + classes.tableHeadCell
                      }
                      key={key}
                    >
                      <TableSortLabel
                        active={orderBy === name}
                        direction={order}
                        onClick={handleSortByChange(name)}
                      >
                        {name}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {(stableSort(tableData, getComparator(order, orderBy)) || [])
              .slice(pageNumber * pageSize, pageNumber * pageSize + pageSize)
              .map((partner, key) => {
                return (
                  !partner.isSEZ && (
                    <TableRow
                      hover
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        navigate(
                          `${programLink}/partner/?id=${partner.institutionID}`
                        )
                      }
                      key={key}
                      className={classes.tableBodyRow}
                    >
                      <TableCell className={classes.tableCell}>
                        <Tooltip
                          title={partner.institutionName}
                          placement="top-start"
                        >
                          <span>{partner.institutionName}</span>
                        </Tooltip>

                        <span className="cellContents">
                          <span className="sourceLabel">
                            <SourceChip
                              sourceName={
                                partner.institutionType.institutionTypeTitle ||
                                'NATIONAL'
                              }
                              sourceId={
                                partner.institutionType.institutionTypeID || '0'
                              }
                            />
                          </span>
                        </span>
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {partner ? (
                          <Tooltip
                            title={
                              partner.institutionDescription &&
                              partner.institutionDescription.length > 0
                                ? partner.institutionDescription
                                : 'NA'
                            }
                            placement="top-start"
                          >
                            <span>
                              {partner.institutionDescription &&
                              partner.institutionDescription.length > 0
                                ? partner.institutionDescription
                                : 'NA'}
                            </span>
                          </Tooltip>
                        ) : null}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {partner ? (
                          <Tooltip
                            title={
                              getAppendedCommaValue(
                                partner.region ? partner.region.regionName : ''
                              ) +
                              getAppendedCommaValue(
                                partner.city ? partner.city.cityName : ''
                              ) +
                              partner.country.countryName
                            }
                            placement="top-start"
                          >
                            <span>
                              {getAppendedCommaValue(
                                partner.region ? partner.region.regionName : ''
                              ) +
                                getAppendedCommaValue(
                                  partner.city ? partner.city.cityName : ''
                                ) +
                                partner.country.countryName}
                            </span>
                          </Tooltip>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40]}
                colSpan={4}
                count={totalProjects}
                rowsPerPage={[pageSize]}
                page={pageNumber}
                SelectProps={{
                  inputProps: { 'aria-label': t('partners_per_page') },
                  native: true,
                }}
                labelRowsPerPage={t('partners_per_page')}
                onChangePage={handlePageNumberChange}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      ) : (
        <NoProjects data="Partners" />
      )}
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: 'gray',
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object),
  onSortByChange: PropTypes.any,
  onSortOrderChange: PropTypes.any,
  onPageNumberChange: PropTypes.any,
  onPageSizeChange: PropTypes.any,
  totalProjects: PropTypes.any,
  isLoading: PropTypes.bool,
  fallback: PropTypes.element,
  programLink: PropTypes.string,
  programCode: PropTypes.string,
  handleFavoriteProjects: PropTypes.func,
  favoriteProjects: PropTypes.array,
  isFavoritesView: PropTypes.bool,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
};

export default withStyles(tableStyle)(CustomTable);
